.page-login {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .wrap {
    // background-color: rgba($color: #000000, $alpha: 0.3);
    padding: 24px;
    border-radius: 12px;
    width: 300px;
  }
  .header {
    text-align: center;
    padding: 0 0 20px 0;
    .icon {
      width: 100%;
      height: 100px;
      svg {
        width: 100px;
        height: 100px;
      }
    }
    h3 {
      padding-top: 5px;
      font-size: 24px;
    }
    p {
      font-size: 14px;
      color: rgba($color: #fff, $alpha: 0.8);
    }
  }
  h1 {
    font-weight: 800;
    font-size: 22px;
    padding: 12px;
    text-align: center;
  }
  .form-footer {
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    line-height: 32px;
    padding: 0 12px;
    .link {
      a {
        color: #eee;
        &:hover {
          color: #177ddc;
        }
      }
    }
    .wallet {
      cursor: pointer;
      color: #eee;
      &:hover {
        color: #177ddc;
      }
    }
  }
  input {
    height: 42px;
    border-radius: 10px;
    font-size: 16px;
  }
  .submit {
    width: 100%;
    height: 42px;
    display: block;
    border-radius: 10px;
    font-size: 16px;
    background: rgba(78, 136, 248, 0.4), rgb(65, 140, 253);
  }
}
.imgtest {
  img {
    float: left;
  }
}
