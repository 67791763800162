.captcha {
  height: 32px;
}
.publish-modal {
  .ant-modal-title {
    text-align: center;
    font-size: 1.7em;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    margin-bottom: 16px;
  }
  .footer-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.modal-publish-form {
  .ant-form-item {
    margin-bottom: 12px;
  }
}
