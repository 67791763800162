@import "../../../../assets/style/color.scss";
.wgitem {
  .wrap {
    overflow: hidden;
    border-radius: 8px;
    background-color: #fff;
    padding: 0 0 12px 0;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      transform: translateY(-6px);
      box-shadow: 0 26px 16px -24px rgb(0 36 100 / 30%);
    }
  }
  &:hover {
    .intro {
      color: #000;
    }
  }
  &:focus {
    background-color: rgba($color: #000000, $alpha: 0.03);
  }
  .qrcode {
    width: 100%;
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-color: #f1f1f1;
  }
  .intro {
    padding: 12px;
    color: #0d0c22;
    height: 78px;
    overflow: hidden;
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: center;
    }
    .date {
      display: flex;
      align-items: baseline;
      justify-content: center;
      color: rgba($color: #000000, $alpha: 0.7);
      font-size: 12px;
      line-height: 18px;
      padding-top: 5px;
      strong {
        font-size: 20px;
        color: #000;
      }
    }
  }
}
