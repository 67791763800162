@import "../../../assets/style/color.scss";

.comp-header {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
  .wrap {
    width: 100%;
    max-width: 1188px;
    padding: 0 6px;
    display: flex;
    align-items: center;
  }
  .logo {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      color: #fff;
      font-size: 26px;
      &:hover {
        color: #fff;
      }
      img {
        height: 32px;
      }
    }
  }
  .feature {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.comp-wgitem {
  background-color: #fff;
  box-sizing: border-box;
  padding: 12px;
  .skeleton {
    margin-top: 6px;
    .ant-skeleton-paragraph {
      margin-block-start: 6px !important;
      li {
        margin-block-start: 6px !important;
      }
    }
  }
}
