@import "./reset.scss";
@import "./color.scss";
@import "./mapp.scss";

@font-face {
  font-family: "digit";
  src: url("../fonts/digit.ttf");
}

body {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f5f5f5;
  color: #1b1d1f;
}

#root,
.app {
  width: 100%;
  height: 100%;
  display: flex;
}

.ell2 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ell {
  white-space: nowrap;
  text-overflow: ellipsis; /*超出的设置为省略号*/
}

.svg {
  &:hover {
    svg path {
      stroke: #ff9500;
    }
  }
  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
    path {
      stroke: rgb(136, 136, 136);
    }
  }
}

a,
.hover {
  color: #333;
  &:hover {
    color: $colorPrimaryTextHover;
  }
  &:active {
    color: $colorPrimaryActive;
  }
}

$-border-color: rgba(255, 255, 255, 0.2);

.table {
  overflow: hidden;
  list-style: none;
  padding: 0 !important;
  border-top: 1px solid $-border-color;
  border-left: 1px solid $-border-color;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  li {
    text-align: center;
    color: var(--el-text-color-regular);
    border-right: 1px solid $-border-color;
    border-bottom: 1px solid $-border-color;
    transition: background-color 0.3s;
    &:hover {
      background-color: #2b2b2c;
    }
  }
}

.page-title {
  font-size: 24px;
  padding: 24px 0;
}

.page-center {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.ant-layout-footer a {
  color: #333;
}
