// 移动端下的样式覆盖
.mapp {
}

@media (max-width: 767px) {
  #root {
    .layout-sidebar-menu {
      width: 100%;
      height: 42px;
      background: #1b1d1f;
      z-index: 9;
    }
    .layout-app-content {
      padding: 54px 0 0 0;
    }

    // header
    .comp-header .logo a {
      padding-left: 0.5em;
      font-size: 1.5em;
    }
    // home
    .catesthumb {
      width: 100%;
      .wrap {
        width: calc(100% + 24px);
        .item {
          width: 50%;
        }
      }
    }
    .banner {
      height: 120px;
    }
    .catesthumb {
      flex-wrap: wrap;
      .item {
        width: 31.3%;
        margin-right: 3%;
        margin-bottom: 12px;
        &:nth-child(3),
        &:nth-child(6) {
          margin-right: 0;
        }
      }
    }
    .cates-list {
      padding: 0 0.5rem;
      .list .item {
        width: 50%;
        &:nth-child(5) {
          display: none;
        }
      }
    }

    //page list
    .page-list .banner {
      .cateinfo {
        height: 100% !important;
        h3 {
          font-size: 1em;
        }
        p {
          font-size: 0.7em;
          padding: 0.2em 1em;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  #root {
    .page-center {
      max-width: 750px;
    }
    .banner {
      height: 240px;
    }
    .cates-list .list .item {
      width: 33.33%;
      &:nth-child(4) {
        display: none;
      }
      &:nth-child(5) {
        display: none;
      }
    }
  }
}

@media (min-width: 992px) {
  #root {
    .page-center {
      max-width: 970px;
    }
    .catesthumb .wrap {
      width: 992px;
    }
    .banner {
      height: 220px;
    }
    .cates-list .list .item {
      width: 25%;
      &:nth-child(4) {
        display: block;
      }
      &:nth-child(5) {
        display: none;
      }
    }
  }
}

@media (min-width: 1200px) {
  #root {
    .page-center {
      max-width: 1188px;
    }
    .catesthumb .wrap {
      width: 1212px;
    }
    .banner {
      height: 340px;
    }
    .cates-list .list .item {
      width: 20%;
      &:nth-child(4) {
        display: block;
      }
      &:nth-child(5) {
        display: block;
      }
    }
  }
}
