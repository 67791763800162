.page-list {
  .banner {
    height: 250px;
    overflow: hidden;
    position: relative;
    padding: 12px 6px 0;
    img {
      width: 100%;
    }
    .cateinfo {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      text-shadow: 0 0 1px rgba($color: #000000, $alpha: 0.5);
      h3 {
        font-size: 26px;
        color: rgba($color: #ffffff, $alpha: 0.98);
        text-align: center;
      }
      p {
        font-size: 16px;
        color: rgba($color: #ffffff, $alpha: 0.77);
        text-align: center;
      }
    }
  }
}

.page-remove {
  .feature {
    padding-top: 24px;
  }
  .list {
    padding-top: 24px;
    .thumb {
      max-height: 64px;
      width: auto;
    }
  }
}
