@import "./color.scss";
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
  font-size: 14px;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

ol,
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #333;
}

// html {
//   &::-webkit-scrollbar {
//     /*滚动条整体样式*/
//     width: 6px;
//   }
//   &::-webkit-scrollbar-thumb {
//     /*滚动条里面小方块*/
//     border-radius: 3px;
//     box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
//     background: $colorPrimary;
//   }
//   &::-webkit-scrollbar-track {
//     /*滚动条里面轨道*/
//     box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
//     border-radius: 3px;
//     background: #1b1d1f;
//   }
// }
