.modal-qrcode-detail {
  .qrcode {
    height: 320px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 520px;
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
    padding: 12px 24px;
    text-align: center;
  }
  .date {
    span {
      font-size: 12px;
      color: rgba($color: #000000, $alpha: 0.7);
    }
    strong {
      font-size: 24px;
    }
  }
}
