.banner {
  height: 320px;
  overflow: hidden;
  position: relative;
  padding: 12px 6px 0;
  margin-bottom: 18px;
  .img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}
.cates-list {
  .info {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    position: relative;
    &:after {
      position: absolute;
      top: 54px;
      display: block;
      width: 100%;
      height: 1px;
      background: rgba(5, 5, 5, 0.06);
      content: "";
    }
    .title {
      flex: 1;
      padding: 0 0 10px 0;
      h3 {
        font-size: 18px;
        font-weight: 500;
        padding: 0 6px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        padding: 0 6px;
        color: rgba(5, 5, 5, 0.7);
      }
    }
    .more {
      padding: 0 6px;
      font-size: 14px;
      a {
        color: #333;
      }
    }
  }
  .list {
    padding: 6px 0 12px;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 20%;
      padding: 6px 6px 12px 6px;
      overflow: hidden;
    }
  }
}
.cates-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.catesthumb {
  width: 100%;
  padding: 0 6px;
  margin-bottom: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item {
    width: 90px;
    height: 2.5em;
    overflow: hidden;
    border-radius: 10px;
    background-color: #68498b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    &:nth-child(2) {
      background-color: #fd8224;
    }
    &:nth-child(3) {
      background-color: #d82c26;
    }
    &:nth-child(4) {
      background-color: #1174cb;
    }
    &:nth-child(5) {
      background-color: #107c7e;
    }
    &:nth-child(6) {
      background-color: #20bf64;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}
.empty-list {
  width: 100%;
}
